import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<void, 'links'>({ name: 'FooterRedesignMainContent' })(
  (...[theme, , classes]) => ({
    fullWidthWrapper: {
      width: '100%',
    },
    subHeader: {
      color: theme.palette.text.primary,
      marginBottom: 10,
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
        marginTop: 20,
      },
    },
    copyrightText: {
      color: theme.palette.text.secondary,
      margin: '0 auto 20px',
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: 50,
        paddingRight: 50,
        textAlign: 'center',
      },
    },
    disclaimerText: {
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
      margin: '0px auto',
      textAlign: 'center',
      width: 720,
    },
    sectionItem: {
      margin: '5px 0px',
      [theme.breakpoints.down('lg')]: {
        alignItems: 'center',
      },
    },
    links: {
      ...theme.typography.button2Underline,
      color: theme.palette.text.link,
    },
    linksDivider: {
      color: theme.palette.other.divider,
      margin: 0,
      marginLeft: 30,
      marginRight: 30,
      height: 22,
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    },
    centerContent: {
      [theme.breakpoints.down('lg')]: {
        display: 'flex',
        justifyContent: 'center',
      },
    },
    contactUsColumn: {
      [theme.breakpoints.up('lg')]: {
        marginRight: 40,
      },
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    hoursColumn: {
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    borderedContainer: {
      [theme.breakpoints.down('lg')]: {
        border: `1px solid ${theme.palette.primary.border}`,
        height: 50,
        borderRadius: 4,
        padding: 0,
        margin: '0 20px 10px',
        [`& .${classes.links}`]: {
          ...theme.typography.button,
          color: theme.palette.primary.main,
          textTransform: 'none',
          textDecoration: 'none',
          textAlign: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        },
      },
    },
    copyDivider: {
      color: theme.palette.other.divider,
      width: '100%',
      margin: '20px 0',
    },
    hoursOfOperationText: {
      ...theme.typography.body3,
      color: theme.palette.text.primary,
      textAlign: 'center',
      marginBottom: 20,
      [theme.breakpoints.up('lg')]: {
        marginBottom: 0,
        marginTop: 10,
        textAlign: 'left',
      },
    },
  }),
);
