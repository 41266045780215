import { LogoGMInsuranceBlueCobrandedImageUrl } from '@ecp/themes/base';

import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  styledLogoImage: true,
  primaryLogo: LogoGMInsuranceBlueCobrandedImageUrl,
  showVerticalDivider: false,
  showLogoOnlyOnMobile: true,
};

export default metadata;
