import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './MainContent.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css } = useStylesTss();

  const styles = useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        borderedContainer: css({
          borderRadius: 25,
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });

  return styles;
};
