import { memo } from 'react';

import { Box, Divider } from '@mui/material';

import { PhoneLink, ProductQuoteNumber } from '@ecp/features/sales/shared/components';
import { partner } from '@ecp/partners';

import { useStyles } from '../QuotesError.styles';

export const CNQ: React.FC = memo(() => {
  const { classes } = useStyles();

  return (
    <Box>
      <h1>Whoops! No quotes available</h1>
      <ProductQuoteNumber />
      <Divider aria-hidden='true' className={classes.divider} />
      <p className={classes.guidance}>
        Unfortunately, General Motors Insurance was unable to provide you with an online quote at
        the moment. We&apos;ve arranged for you to speak with a licensed advisor who can help.
        Please call us at
        <PhoneLink withUnderlinedLinkStyle number={partner.shared.qnbPhoneNumber} />.
      </p>
    </Box>
  );
});
