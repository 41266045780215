import { Divider, Grid } from '@mui/material';

import { PhoneLink } from '@ecp/components';
import { ProductUpsells } from '@ecp/features/sales/shared/components';
import { getLineOfBusiness } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';
import { IconCardCondo, IconCardHomeWShadow, IconCardRent } from '@ecp/themes/partners/gmi';

import { Main } from '../Main';
import { useStyles } from './GetQuote.styles';
import metadata from './metadata';

const Products = [
  {
    Icon: IconCardCondo,
    header: 'Condo',
    text: 'Covers the interior of the unit and your belongings.',
  },
  {
    Icon: IconCardHomeWShadow,
    header: 'Second home',
    text: 'Protects your investment in a non-primary residence.',
  },
  {
    Icon: IconCardRent,
    header: 'Renters',
    text: 'Protect your belongings in your residence.',
  },
];

export const GetQuote: React.FC = () => {
  const { classes } = useStyles();
  const lineOfBusiness = useSelector(getLineOfBusiness);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.quoteContainer}>
        <Grid item xs={12} className={classes.desktopHeading}>
          <h1>
            {lineOfBusiness
              ? metadata[lineOfBusiness]?.pageTitle
              : metadata[LineOfBusiness.RENTERS]?.pageTitle}
          </h1>
        </Grid>
        <Main />
      </Grid>

      <Grid item xs={12} className={classes.dividerContainer}>
        <Divider className={classes.divider} />
      </Grid>

      <Grid item xs={12} className={classes.productUpsellsContainer}>
        <ProductUpsells
          desktopListClassOverride={classes.productUpsellsDesktopListOverride}
          desktopStyleBreakpoint='md'
          header='More insurance options for General Motors Insurance customers through Homesite'
          subHeader={
            <>
              Call a licensed advisor at
              <PhoneLink
                number={partner.shared.salesPhoneNumber}
                trackingLabel='phone_number'
                trackingName='top_phone_number_link'
                withUnderlinedLinkStyle
                variant='medium'
              />{' '}
              to get a quote
            </>
          }
          products={Products}
        />
      </Grid>
    </Grid>
  );
};
