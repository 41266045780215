import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'TipContent' })((theme) => ({
  paymentTipContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  paymentTips: theme.typography.body1Bold,
  paymentTipsList: {
    fontWeight: 'normal',
    marginTop: 0,
    paddingLeft: 25,
  },
}));
