import { GridItem } from '@ecp/components';

import {
  BusinessOnPremisesQuestion,
  FoundationTypeQuestion,
  GarageTypeQuestion,
  LivingAreaQuestion,
  MarketValueQuestion,
  NumberOfStoriesQuestion,
  OccupancyTypeQuestion,
  SingleOrMultiFamilyQuestion,
  StyleOfHomeQuestion,
  YearBuiltQuestion,
} from '../../components';

export const HomeBasicFormQuestions: React.FC = () => {
  return (
    <>
      <GridItem xs={12}>
        <MarketValueQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <YearBuiltQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <OccupancyTypeQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <SingleOrMultiFamilyQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <LivingAreaQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <StyleOfHomeQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <NumberOfStoriesQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <FoundationTypeQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <GarageTypeQuestion />
      </GridItem>

      <GridItem topSpacing='lg' xs={12}>
        <BusinessOnPremisesQuestion />
      </GridItem>
    </>
  );
};
