import { useMediaQuery } from '@mui/material';

import { useTheme } from '@ecp/themes/base';

export const getShouldShowDesktopStyleList = ({
  desktopStyleBreakpoint,
  upMd,
  upLg,
}: {
  desktopStyleBreakpoint: 'md' | 'lg';
  upMd: boolean;
  upLg: boolean;
}): boolean => {
  if (desktopStyleBreakpoint === 'md') {
    return upMd;
  }

  if (desktopStyleBreakpoint === 'lg') {
    return upLg;
  }

  // show mobile style list when viewport is "less than" md
  return false;
};

/**
 * Determines whether the desktop style list should be displayed based on
 * the specified breakpoint ('md' or 'lg'). This hook uses `useMediaQuery`
 * to evaluate the current screen size against the provided `desktopStyleBreakpoint`
 * and returns a boolean indicating if the desktop style should be applied.
 */
export const useShouldShowDesktopStyleList = ({
  desktopStyleBreakpoint,
}: {
  desktopStyleBreakpoint: 'md' | 'lg';
}): boolean => {
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const upLg = useMediaQuery(theme.breakpoints.up('lg'));
  const shouldShowDesktopStyle = getShouldShowDesktopStyleList({
    desktopStyleBreakpoint,
    upMd,
    upLg,
  });

  return shouldShowDesktopStyle;
};
