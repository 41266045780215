import { sharedHeaderMetadata } from './metadata.js';
import type { StateMetadata } from './metadata.js';

export const headerMetadata: {
  [productKey: string]: StateMetadata;
} = {
  'homesite.home': {
    ...sharedHeaderMetadata,
  },
};
