import {
  DERIVED_DISCOUNT_BUNDLE,
  DERIVED_DISCOUNT_HOMEOWNER,
  HOME_OWNERSHIP,
} from '@ecp/features/sales/shared/constants';
import { getLineOfBusiness, useField } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';

import { DiscountsFormBody as DiscountsFormBodyBase } from './DiscountsFormBody.js';

export const DiscountsFormBody: React.FC = () => {
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const homeOwner = useField(HOME_OWNERSHIP);

  const discountChecks = {
    [DERIVED_DISCOUNT_BUNDLE]: lineOfBusiness === LineOfBusiness.BUNDLE,
    [DERIVED_DISCOUNT_HOMEOWNER]:
      lineOfBusiness === LineOfBusiness.BUNDLE || homeOwner?.value === 'RESIDENCY_TYPE.OWN',
  };

  return <DiscountsFormBodyBase discountChecks={discountChecks} />;
};
