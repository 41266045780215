import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SubtitleTextCard' })((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    flexGap: 30,
    padding: '10px 20px',
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      marginTop: 20,
    },
    display: 'flex',
    alignItems: 'center',
  },
  policyIcon: {
    height: 120,
    width: 100,
  },
  text: theme.typography.subtitle,
}));
