import { cloneElement, forwardRef, useCallback } from 'react';

import type { FormControlLabelProps } from '@mui/material';
import { FormControlLabel, Stack } from '@mui/material';

import type { Option } from '@ecp/types';

import type { RadioGroupOption } from '../../RadioGroup';
import { useStyles } from './RadioCardFormControlLabel.styles';

export interface RadioCardFormControlLabel
  extends Omit<FormControlLabelProps, 'label' | 'isDisabled'> {
  option: RadioGroupOption;
  caption?: boolean;
  variant?: 'largeRadioCard' | 'button' | 'yesNoButton' | 'roundButton';
  autoFocus?: boolean;
  /** Whether the component is disabled or not, use this prop instead `disabled` in order to disable all children components */
  isDisabled?: boolean;
  onButtonClick?(val: string): void;
  children?: React.ReactNode;
}

/** Card component with Radio toggle inside can be used inside RadioGroup. */
export const RadioCardFormControlLabel = forwardRef<HTMLButtonElement, RadioCardFormControlLabel>(
  (props, ref) => {
    const {
      checked,
      className,
      control,
      isDisabled,
      option,
      caption = false,
      variant,
      autoFocus = false,
      onButtonClick,
      children,
      ...rest
    } = props;
    const { classes, cx } = useStyles();
    const handleClick = useCallback(
      () => onButtonClick?.(option.value),
      [onButtonClick, option.value],
    );

    const isLargeText = variant === 'largeRadioCard';

    return (
      <FormControlLabel
        {...rest}
        {...(option as Option)}
        className={cx(
          classes.root,
          isLargeText && !caption && classes.largeTextRoot,
          className,
          checked && classes.checked,
        )}
        checked={checked}
        onChange={handleClick}
        ref={ref}
        classes={{ label: classes.labelRoot }}
        label={
          <span
            className={cx(
              classes.labelWrapper,
              isLargeText && classes.largeTextLabelWrapper,
              caption && classes.captionLabelWrapper,
            )}
          >
            {!caption && option.description && (
              <Stack direction='column' width='100%'>
                <span className={cx(isLargeText && classes.largeTextDescription)}>
                  {option.description}
                </span>
                {option.subdescription && (
                  <span className={classes.captionWithDescription}>{option.subdescription}</span>
                )}
              </Stack>
            )}
            <span
              className={cx(
                classes.label,
                isLargeText && classes.largeTextLabelBold,
                checked && classes.checked,
                caption && isLargeText && classes.largeTextLabelWithCaption,
                !!option.description && !caption && classes.labelToTheRight,
                !option.description && isLargeText && classes.largeTextLabel,
              )}
            >
              {option.label}
            </span>
            {caption && option.description && (
              <span className={classes.caption}>{option.description}</span>
            )}
            {option.icon && (
              <div
                className={cx(
                  classes.icon,
                  classes.svgHorizontal,
                  checked && classes.iconChecked,
                  isDisabled && !checked && classes.iconDisabled,
                )}
              >
                {option.icon}
              </div>
            )}
            {/* Render additional children if passed*/}
            {children && <div>{children}</div>}
          </span>
        }
        control={cloneElement(control, { autoFocus })}
        disabled={isDisabled}
      />
    );
  },
);
