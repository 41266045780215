import { DERIVED_DISCOUNT_AUTO, DERIVED_DISCOUNT_HOME } from '@ecp/features/sales/shared/constants';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { IconCardPartnerDiscount } from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

import {
  DISCOUNT_AUTO_4YEAR_DEGREE,
  DISCOUNT_AUTO_ANTI_THEFT,
  DISCOUNT_AUTO_AWAY_AT_SCHOOL,
  DISCOUNT_AUTO_DEFENSIVE_DRIVER,
  DISCOUNT_AUTO_GOOD_STUDENT,
  DISCOUNT_AUTO_KEPT_IN_GARAGE,
  DISCOUNT_AUTO_PAPERLESS,
  DISCOUNT_AUTO_PAY,
  DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_BURGLAR,
  DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_FIRE,
  DISCOUNT_PROPERTY_HOME_BUYER,
  DISCOUNT_PROPERTY_INDOOR_SPRINKLER,
} from './metadata.js';

const DISCOUNT_PROPERTY_GMI_AUTO_POLICY: CardOption = {
  value: DERIVED_DISCOUNT_AUTO,
  label: 'General Motors Insurance Auto Bundle',
  icon: <IconCardPartnerDiscount />,
  helpText:
    'If you have an auto policy with General Motors Insurance, or you plan to, you may qualify for a discount. Any available discounts will be calculated in your quote. Discounts may vary by state.',
};

const DISCOUNT_PROPERTY_GMI_HOME_POLICY: CardOption = {
  value: DERIVED_DISCOUNT_HOME,
  label: 'General Motors Insurance Home Policy',
  icon: <IconCardPartnerDiscount />,
  helpText:
    'If you have an Home policy with General Motors Insurance, or you plan to, you may qualify for a discount. Any available discounts will be calculated in your quote. Discounts may vary by state.',
};

export const discountOptions: Record<LineOfBusiness, CardOption[]> = {
  [LineOfBusiness.AUTO]: [
    DISCOUNT_AUTO_GOOD_STUDENT,
    DISCOUNT_AUTO_PAPERLESS,
    DISCOUNT_AUTO_AWAY_AT_SCHOOL,
    DISCOUNT_AUTO_PAY,
    DISCOUNT_AUTO_DEFENSIVE_DRIVER,
    DISCOUNT_AUTO_ANTI_THEFT,
    DISCOUNT_AUTO_KEPT_IN_GARAGE,
    DISCOUNT_AUTO_4YEAR_DEGREE,
    DISCOUNT_PROPERTY_GMI_HOME_POLICY,
  ],
  [LineOfBusiness.HOME]: [
    DISCOUNT_PROPERTY_HOME_BUYER,
    DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_BURGLAR,
    DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_FIRE,
    DISCOUNT_PROPERTY_INDOOR_SPRINKLER,
    DISCOUNT_PROPERTY_GMI_AUTO_POLICY,
  ],
  [LineOfBusiness.BUNDLE]: [
    DISCOUNT_PROPERTY_HOME_BUYER,
    DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_BURGLAR,
    DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_FIRE,
    DISCOUNT_PROPERTY_INDOOR_SPRINKLER,
  ],
  [LineOfBusiness.RENTERS]: [],
  [LineOfBusiness.BUNDLE_AUTO_RENTERS]: [],
};
