import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './RetrieveSlider.styles.js';

export * from './RetrieveSlider.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  const styles = useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        mainContent: css({
          marginTop: 40,
        }),
        mainContentSectionA: css({
          paddingBottom: 25,
          [theme.breakpoints.down('lg')]: {
            paddingBottom: 9,
          },
        }),
        mainContentSectionB: css({
          paddingBottom: 25,
          [theme.breakpoints.down('lg')]: {
            paddingBottom: 9,
          },
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });

  // Reset the following classes rather than merge
  styles.classes.mainCard = '';

  return styles;
};
