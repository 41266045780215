import { Divider, Grid } from '@mui/material';

import { ContactInfo } from './ContactInfo';
import { LegalLinks } from './LegalLinks';
import { useStyles } from './MainContent.styles';

interface Props {
  savingsTextElement?: React.ReactElement<React.PropsWithChildren>;
  showFAQLink?: boolean;
  showAccessibilityLink?: boolean;
  copyrightText: React.ReactNode;
  disclaimerText: React.ReactNode;
  showLiveChat?: boolean;
}

export const MainContent: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const {
    copyrightText,
    disclaimerText,
    showAccessibilityLink = false,
    showFAQLink = false,
    showLiveChat = true,
  } = props;

  return (
    <Grid container className={classes.fullWidthWrapper}>
      <Divider aria-hidden='true' variant='middle' className={classes.copyDivider} />
      <ContactInfo showLiveChat={showLiveChat} />
      <LegalLinks showFAQLink={showFAQLink} showAccessibilityLink={showAccessibilityLink} />
      <Divider aria-hidden='true' variant='middle' className={classes.copyDivider} />
      <p className={classes.copyrightText}>{copyrightText}</p>
      <p className={classes.disclaimerText}>{disclaimerText}</p>
    </Grid>
  );
};
