import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './RatingCriteriaForm.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        sidebarContainer: css({
          '@supports (position: sticky)': {
            top: 115,
          },
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
