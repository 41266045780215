import { Grid } from '@mui/material';

import { useEffectOnce } from '@ecp/utils/react';

import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { RetrieveSlider } from '@ecp/features/sales/shell';

import { useStyles } from './LandingPage.styles.gmi';
import { useLandingPage } from './util';

export const LandingPage: React.FC = () => {
  const { classes, cx } = useStyles();
  const { formType, isLoading, isRetrieve, quoteFormComponent, retrieveFormComponent } =
    useLandingPage();

  useEffectOnce(() => {
    trackSapiAnalyticsEvent({
      element: 'choice.landingPage.page',
      event: 'render',
      eventDetail: 'true',
    });
  }, []);

  if (isLoading) return null;

  return (
    <div className={classes.root} role='main'>
      <Grid
        container
        className={cx(classes.mainContainer, isRetrieve && classes.mainContainerIsRetrieve)}
      >
        <RetrieveSlider
          positionB={isRetrieve}
          quoteForm={quoteFormComponent}
          retrieveForm={retrieveFormComponent}
          formType={formType}
          classes={{ mainContentSlider: classes.mainContentSlider }}
        />
      </Grid>
    </div>
  );
};
