import { Fragment, useMemo } from 'react';

import { Divider, Grid } from '@mui/material';
import type { SetOptional } from 'type-fest';

import { isTruthy } from '@ecp/utils/common';

import { Link } from '@ecp/features/sales/shared/components';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { useIsDesktop } from '@ecp/themes/base';

import { useStyles } from './MainContent.styles';

type TLegalLink = {
  href: PagePath;
  label: string;
  text: string;
  trackingName: string;
  trackingLabel: string;
};
type TLegalLinks = (TLegalLink | SetOptional<TLegalLink, 'trackingName' | 'trackingLabel'>)[];

const useLegalLinks = (showFAQLink: boolean, showAccessibilityLink: boolean): TLegalLinks => {
  return useMemo(
    () =>
      [
        {
          href: PagePath.PARTICIPATING_INSURERS,
          label: 'ParticipatingInsurers',
          text: 'Participating Insurers',
          trackingName: 'participating_insurers_link',
          trackingLabel: 'participating_insurers',
        },
        {
          href: PagePath.PRIVACY_POLICY,
          label: 'PrivacyPolicy',
          text: 'Privacy',
          trackingName: 'privacy_policy_link',
          trackingLabel: 'privacy_policy',
        },
        showAccessibilityLink && {
          href: PagePath.ACCESSIBILITY,
          label: 'Accessibility',
          text: 'Accessibility',
        },
        {
          href: PagePath.TERMS_OF_USE,
          label: 'TermsOfUse',
          text: 'Terms of Use',
          trackingName: 'terms_of_use_link',
          trackingLabel: 'terms_of_use',
        },
        showFAQLink && {
          href: PagePath.FAQ,
          label: 'FAQ',
          text: 'FAQ',
        },
      ].filter(isTruthy),
    [showAccessibilityLink, showFAQLink],
  );
};

export const LegalLinks: React.FC<{
  showFAQLink: boolean;
  showAccessibilityLink: boolean;
}> = ({ showFAQLink, showAccessibilityLink }) => {
  const { classes } = useStyles();
  const isDesktop = useIsDesktop();
  const legalLinkList = useLegalLinks(showFAQLink, showAccessibilityLink);

  return (
    <Grid
      container
      item
      lg={6}
      sm={12}
      alignItems={!isDesktop ? 'center' : 'flex-start'}
      direction={!isDesktop ? 'column' : 'row'}
      alignContent='center'
      justifyContent={!isDesktop ? 'space-around' : 'flex-end'}
    >
      {legalLinkList.map((link, index) => (
        <Fragment key={`${index}=${link.label}`}>
          {index > 0 && (
            <Divider
              aria-hidden='true'
              orientation='vertical'
              variant='middle'
              className={classes.linksDivider}
            />
          )}
          <p className={classes.sectionItem} key={link.href}>
            <Link
              className={classes.links}
              data-label={link.label}
              href={link.href.replace(/^\//, '')}
              target='_blank'
              title={link.label}
              trackingName={link.trackingName}
              trackingLabel={link.trackingLabel}
            >
              {link.text}
            </Link>
          </p>
        </Fragment>
      ))}
    </Grid>
  );
};
