import { memo } from 'react';

import { Box, Divider } from '@mui/material';

import { PhoneLink, ProductQuoteNumber } from '@ecp/features/sales/shared/components';
import { getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { partner } from '@ecp/partners';

import { useStyles } from '../QuotesError.styles';
import type { DeclinationData } from '../types';

// CPUI-4564 Onstar states list requiring mailed letter
const STATES_REQUIRING_NOTICE = new Set([
  'PA',
  'NJ',
  'CA',
  'NC',
  'VA',
  'NY',
  'MI',
  'AK',
  'MA',
  'MO',
  'DC',
  'FL',
  'ND',
  'WV',
]);

export const DNQ: React.FC<Partial<DeclinationData>> = memo(() => {
  const { classes } = useStyles();
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const doesRequireNotice = STATES_REQUIRING_NOTICE.has(stateCode);

  return (
    <Box>
      <h1>Whoops! No quotes available</h1>
      <ProductQuoteNumber />
      <Divider aria-hidden='true' className={classes.divider} />
      <p className={classes.guidance}>
        We are unable to provide you with a quote.{' '}
        {doesRequireNotice && 'You will receive a written declination notice in the mail.'}
      </p>
      <p className={classes.guidance}>
        If you would like to understand why we could not provide you with a quote, please contact us
        at
        <PhoneLink withUnderlinedLinkStyle number={partner.shared.dnqPhoneNumber} />.
      </p>
    </Box>
  );
});
