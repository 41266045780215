import { memo } from 'react';

import { useCheckDriverDiscount } from '@ecp/features/sales/quotes/auto';
import { IconCardGMEmployeeDiscount } from '@ecp/themes/partners/gmi';

import type { DiscountsBlockProps } from './DiscountsBlock.js';
import { DiscountsBlock as DiscountsBlockBase } from './DiscountsBlock.js';
import { useStyles } from './DiscountsBlock.styles.gmi.js';

export const DiscountsBlock: React.FC<DiscountsBlockProps> = memo((props) => {
  const { classes } = useStyles();
  const { product } = props;

  // TODO - GM Employee discount should be integrated with items prop instead of being shown separately
  const isGMEmployee = useCheckDriverDiscount('gmEmployee');

  return (
    <>
      {product === 'auto' && isGMEmployee && (
        <div className={classes.gmDiscountBanner}>
          <IconCardGMEmployeeDiscount className={classes.gmDiscountIcon} />
          <p>As a General Motors employee, you are receiving an exclusive benefit.</p>
        </div>
      )}
      <div className={classes.discountsBlockContent}>
        <DiscountsBlockBase {...props} />
      </div>
    </>
  );
});
