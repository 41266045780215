import { Stack } from '@mui/material';

import { useStyles } from './ProductUpsells.styles';
import { useShouldShowDesktopStyleList } from './util';

type Product = {
  Icon: React.ElementType;
  header: string;
  text: string;
};

interface Props {
  header?: string;
  subHeader: React.ReactElement;
  products: Product[];
  /**
   * Defines the breakpoint at which the list switches to a desktop style.
   * For GMI, `desktopStyleBreakpoint` is set to `md`, and for Costco, it is set to `lg`.
   *
   * ### Why is this a prop?
   * Exposing the breakpoint as a prop allows consumers of the `ProductUpsell` component
   * to configure it directly rather than relying on `.xxx` file overrides. This approach
   * enables the same partner to specify different breakpoints for various pages by
   * passing different props, providing greater flexibility.
   */
  desktopStyleBreakpoint: 'md' | 'lg';
  desktopListClassOverride?: string;
}

const MobileProductList: React.FC<{ products: Product[] }> = ({ products }) => {
  const { classes } = useStyles();

  return (
    <Stack gap='10px' className={classes.mobileList}>
      {products.map(({ Icon, header, text }, index) => (
        <Stack direction='row' gap='10px' key={index} className={classes.mobileListItem}>
          <Icon className={classes.mobileProductIcon} />
          <div className={classes.mobileProductTextContainer}>
            <p className={classes.mobileProductTextHeader}>{header}</p>
            <p className={classes.mobileProductText}>{text}</p>
          </div>
        </Stack>
      ))}
    </Stack>
  );
};

const DesktopProductList: React.FC<{ products: Product[]; desktopListClassOverride?: string }> = ({
  products,
  desktopListClassOverride,
}) => {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.desktopList, desktopListClassOverride)}>
      {products.map(({ Icon, header, text }, index) => (
        <div key={index} className={classes.desktopListItem}>
          <Icon className={classes.desktopProductIcon} />
          <p className={classes.desktopProductTextHeader}>{header}</p>
          <p className={classes.desktopProductText}>{text}</p>
        </div>
      ))}
    </div>
  );
};

export const ProductUpsells: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { header, subHeader, products, desktopStyleBreakpoint, desktopListClassOverride } = props;
  const showDesktopStyle = useShouldShowDesktopStyleList({ desktopStyleBreakpoint });

  return (
    <div className={classes.root}>
      <h2 className={classes.productsHeader}>{header}</h2>
      <p className={classes.productsSubHeader}>{subHeader}</p>
      {!showDesktopStyle ? (
        <MobileProductList products={products} />
      ) : (
        <DesktopProductList
          products={products}
          desktopListClassOverride={desktopListClassOverride}
        />
      )}
    </div>
  );
};
