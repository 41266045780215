import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './PostBindForm.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        successIconContainer: css({
          backgroundColor: 'transparent',
          borderColor: theme.palette.primary.main,
          borderStyle: 'solid',
          borderWidth: 2,
        }),
        titleHeader: css({
          color: theme.palette.text.primary,
        }),
        successIcon: css({
          ...theme.mixins.setColorSvg(theme.palette.primary.main),
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
