import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './CoveragesForm.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        root: css({
          [theme.breakpoints.down('md')]: {
            marginTop: 20,
          },
        }),
        policyStartDateDetails: css({
          marginLeft: 15,
          [theme.breakpoints.down('sm')]: {
            display: 'inherit',
          },
        }),
        autoCoverageDiscountsMsg: css({
          padding: 15,
          height: 'auto',
          width: '100%',
          background: theme.palette.info.main,
          lineHeight: '20px',
          marginBottom: 40,
          [theme.breakpoints.down('md')]: {
            marginBottom: 20,
          },
          '& p': {
            ...theme.typography.header1,
            color: theme.palette.grey[100],
            [theme.breakpoints.down('md')]: {
              textAlign: 'center',
            },
          },
        }),
        sidebarContainerRoot: css({
          '@supports (position: sticky)': {
            top: 174,
          },
        }),
        accordionDetailsDiscountBkg: css({
          padding: 0,
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
