import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './GetQuote.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        root: css({
          marginTop: 0,
          padding: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }),
        quoteContainer: css({
          maxWidth: 740,
          padding: '0 15px',
        }),
        dividerContainer: css({
          width: '100%',
          marginTop: 40,
          marginBottom: 60,
          // styles below are to match the footer divider width
          maxWidth: 1084,
          [theme.breakpoints.down('xl')]: {
            paddingLeft: 40,
            paddingRight: 40,
          },
          [theme.breakpoints.down('lg')]: {
            paddingLeft: 30,
            paddingRight: 30,
          },
          [theme.breakpoints.down('md')]: {
            paddingLeft: 16,
            paddingRight: 16,
          },
        }),
        divider: css({
          backgroundColor: theme.palette.divider,
          border: 'none',
          height: 1,
          width: '100%',
        }),
        productUpsellsContainer: css({
          padding: '0 15px',
        }),
        productUpsellsDesktopListOverride: css({
          maxWidth: 804,
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
