import { forwardRef, useCallback, useMemo } from 'react';

import { Divider, FormControlLabel as MuiFormControlLabel } from '@mui/material';

import { emptyArray } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import type { ShowMoreOrLessProps } from '@ecp/components';
import { CardFormControlLabel, ShowMoreOrLess } from '@ecp/components';
import type { AnswerValue, CardOption } from '@ecp/types';

import type { AutoAdjustableGridProps } from '../AutoAdjustableGrid';
import { ButtonFormControlLabel, RadioCardFormControlLabel } from '../FormControlLabel';
import { formatLabel, getSortedOptions } from '../utils';
import { Radio } from './Radio';
import type { RadioGroupProps } from './RadioGroup';
import { RadioGroup } from './RadioGroup';
import { useStyles } from './RadioGroup.styles';

const YES_NO_DEFAULT_OPTIONS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export interface RadioGroupOption extends CardOption {
  description?: React.ReactNode;
  subdescription?: string;
}

export interface RadioGroupWithOptionsProps extends RadioGroupProps {
  cardSize?: 'small' | 'medium' | 'large';
  options?: RadioGroupOption[];
  disabled?: boolean;
  adjustableGrid?: boolean;
  adjustableGridProps?: AutoAdjustableGridProps;
  showMoreOrLessProps?: ShowMoreOrLessProps;
  dataTestId?: string;
  divider?: boolean;
  caption?: boolean;
  actionOnChange?(value: AnswerValue): void;
  children?: React.ReactNode;
  sortByKey?: boolean;
}

export const RadioGroupWithOptions = forwardRef<HTMLButtonElement, RadioGroupWithOptionsProps>(
  (props, ref) => {
    const {
      cardSize,
      id,
      name,
      options: optionsProp,
      value = '',
      variant: variantProp = 'button',
      disabled,
      actionOnChange,
      actionOnComplete,
      trackingName,
      trackingLabel,
      adjustableGrid,
      adjustableGridProps,
      showMoreOrLessProps,
      dataTestId,
      divider,
      caption = false,
      label,
      children,
      sortByKey,
      ...radioGroupProps
    } = props;

    // optionsProp won't default to emptyArray when null is passed, so doing it here.
    const options = useMemo(
      () =>
        sortByKey && optionsProp?.length
          ? getSortedOptions(optionsProp)
          : optionsProp ?? emptyArray,
      [optionsProp, sortByKey],
    );

    const variant =
      variantProp !== 'horizontalCard' &&
      variantProp !== 'radioCard' &&
      variantProp !== 'buttonCardWithRadioButton' &&
      (options[0]?.icon || options[0]?.image || options[0]?.noIcon)
        ? 'card'
        : variantProp;

    const { classes, cx } = useStyles({ ...props, cardCount: options.length });

    if (variant === 'yesNoButton' && options && options !== emptyArray) {
      const message = `Cannot supply options for variant="yesNoButton" for [${name || id}]`;
      datadogLog({
        logType: 'error',
        message,
        context: {
          logOrigin: 'libs/components/src/RadioGroup/RadioGroupWithOptions.tsx',
          functionOrigin: 'RadioGroupWithOptions/yesNoButton',
        },
      });
      throw new Error(message);
    }

    if (options === emptyArray && variant !== 'yesNoButton') {
      const message = `No options for [${name || id}]`;
      datadogLog({
        logType: 'error',
        message,
        context: {
          logOrigin: 'libs/components/src/RadioGroup/RadioGroupWithOptions.tsx',
          functionOrigin: 'RadioGroupWithOptions/ not yesNoButton',
        },
      });
      throw new Error(message);
    }

    const nextOptions: Readonly<CardOption[]> =
      variant === 'yesNoButton' ? YES_NO_DEFAULT_OPTIONS : options;

    const handleClick = useCallback(
      (value: AnswerValue): void => {
        actionOnChange?.(value);
      },
      [actionOnChange],
    );

    const formControlLabels = nextOptions.map((optionNext: CardOption, index: number) => {
      // Removing sortingkey as DOM is accepting it.
      const { sortingKey, ...option } = optionNext;
      const optionCommonProps = {
        key: option.value,
        'data-testid': dataTestId ? `${dataTestId}.${option.value}` : option.value,
      };
      const idForLabel = id ? `${id}-${option.value}` : option.value;
      switch (variant) {
        case 'button':
        case 'yesNoButton':
        case 'roundButton':
          return (
            <ButtonFormControlLabel
              {...optionCommonProps}
              actionOnComplete={actionOnComplete}
              trackingName={trackingName}
              trackingLabel={trackingLabel}
              className={classes.control}
              control={<Radio id={idForLabel} />}
              htmlFor={idForLabel}
              inputRef={ref}
              value={option.value}
              option={option}
              autoFocus={index === 0 && variant === 'roundButton'}
              variant={variant}
              isDisabled={disabled}
            />
          );
        case 'buttonCard':
          return (
            <ButtonFormControlLabel
              key={option.value}
              option={option}
              value={option.value}
              onButtonClick={handleClick}
              variant='button'
              control={
                <Radio value={option.value} checked={value === option.value} disabled={disabled} />
              }
              isDisabled={disabled}
              size={cardSize}
            />
          );
        case 'buttonCardWithRadioButton':
          return (
            <CardFormControlLabel
              {...optionCommonProps}
              className={cx(classes.control, classes.buttonCardRadio)}
              control={<Radio id={idForLabel} data-testid={idForLabel} />}
              htmlFor={idForLabel}
              inputRef={ref}
              option={option}
              divider={divider}
              value={option.value}
              size={cardSize}
              controlType='radio'
            />
          );
        case 'largeRadioCard':
        case 'radioCard':
          return (
            <RadioCardFormControlLabel
              key={option.value}
              onButtonClick={handleClick}
              control={
                <Radio disabled={disabled} value={option.value} checked={value === option.value} />
              }
              value={option.value}
              option={option}
              isDisabled={disabled}
              caption={caption}
              autoFocus={value === option.value}
              ref={index === 0 ? ref : null}
            >
              {children && (
                <>
                  <Divider />
                  {value === option.value && children}
                </>
              )}
            </RadioCardFormControlLabel>
          );
        case 'card':
          return (
            <CardFormControlLabel
              {...optionCommonProps}
              className={classes.control}
              control={<Radio id={idForLabel} data-testid={idForLabel} />}
              htmlFor={idForLabel}
              inputRef={ref}
              option={option}
              divider={divider}
              value={option.value}
              size={cardSize}
              controlType='radio'
              cardWithShowMoreOrLessProps={!!showMoreOrLessProps}
            />
          );
        case 'horizontalCard': {
          return (
            <CardFormControlLabel
              {...optionCommonProps}
              className={classes.control}
              control={<Radio id={idForLabel} data-testid={idForLabel} size='small' />}
              htmlFor={idForLabel}
              inputRef={ref}
              option={option}
              divider={divider}
              value={option.value}
              size='sizeHorizontal'
              variant='horizontalCard'
              controlType='radio'
            />
          );
        }
        default:
          // classic - use MUI
          return (
            <MuiFormControlLabel
              {...optionCommonProps}
              className={classes.control}
              classes={{
                root: classes.classicRoot,
                label: classes.classicLabel,
              }}
              control={
                <Radio id={idForLabel} data-testid={idForLabel} className={classes.classicRadio} />
              }
              htmlFor={idForLabel}
              inputRef={ref}
              key={option.value}
              label={
                <span className={classes.classicRadio}>
                  {formatLabel(option.label, classes.textTertiary)}
                </span>
              }
              value={option.value || ''}
            />
          );
      }
    });

    if (showMoreOrLessProps) {
      formControlLabels.push(
        <ShowMoreOrLess
          cardSize={cardSize !== 'large' ? cardSize : undefined}
          {...showMoreOrLessProps}
          key='showMoreLess'
        />,
      );
    }

    return (
      <RadioGroup
        value={value}
        className={classes.root}
        actionOnChange={handleClick}
        actionOnComplete={actionOnComplete}
        id={id}
        name={name}
        trackingName={trackingName}
        trackingLabel={trackingLabel}
        variant={variant}
        adjustableGrid={adjustableGrid}
        adjustableGridProps={adjustableGridProps}
        cardSize={cardSize}
        label={formatLabel(label, classes.textTertiary)}
        {...radioGroupProps}
      >
        {formControlLabels}
      </RadioGroup>
    );
  },
);
