import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { useSearchParams } from '@ecp/utils/routing';
import { useLocation } from '@ecp/utils/routing';

import { PagePath } from '@ecp/features/sales/shared/routing';

import { useStyles } from './FooterRedesign.styles';
import { MainContent } from './MainContent/MainContent';

const handleFooterClick: React.MouseEventHandler = (event) => {
  const action = event.currentTarget.getAttribute('data-action');
  const label = (event.target as Element).getAttribute('data-label');
  if (action && label) trackClick({ action, label });
};

interface Props {
  savingsTextElement?: React.ReactElement<React.PropsWithChildren> | null;
  showFAQLink?: boolean;
  showAccessibilityLink?: boolean;
  showLiveChat?: boolean;
  elementBelowFooter?: React.ReactNode;
  copyrightTextElement: React.ReactNode;
  disclaimerTextElement: React.ReactNode;
}

export const FooterRedesign: React.FC<Props> = (props) => {
  const {
    savingsTextElement = null,
    showFAQLink = false,
    showAccessibilityLink = false,
    showLiveChat = true,
    elementBelowFooter,
    copyrightTextElement,
    disclaimerTextElement,
  } = props;
  const { classes, cx } = useStyles();

  const { showRetrieve } = useSearchParams();
  const hasSavings = !showRetrieve && !!savingsTextElement?.props.children;

  const location = useLocation();
  const isCheckoutPage = PagePath.CHECKOUT.includes(location.pathname);
  const isCovOrRatingCriteriaPage = [PagePath.RATING_CRITERIA, PagePath.COVERAGES].includes(
    location.pathname,
  );

  return (
    <footer
      className={cx(
        classes.root,
        hasSavings && classes.hasSavings,
        isCheckoutPage && classes.checkoutFooter,
        isCovOrRatingCriteriaPage && classes.coverageFooter,
      )}
      data-action={GoogleAnalyticsLabels.FOOTER}
      onClick={handleFooterClick}
      role='contentinfo'
    >
      <div className={classes.maxWidthContainer}>
        <div className={classes.footerPaddingContainer}>
          <MainContent
            showAccessibilityLink={showAccessibilityLink}
            showFAQLink={showFAQLink}
            showLiveChat={showLiveChat}
            copyrightText={copyrightTextElement}
            disclaimerText={disclaimerTextElement}
          />
        </div>
        {elementBelowFooter}
      </div>
    </footer>
  );
};
