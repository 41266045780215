import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './HeaderMainContent.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        headerBar: css({
          paddingLeft: 20,
          [theme.breakpoints.up('md')]: {
            height: 52,
          },
          [theme.breakpoints.up('lg')]: {
            height: 92,
          },
          [theme.breakpoints.up('xl')]: {
            height: 105,
          },
        }),
        partnerLogoImageStyled: css({
          width: 226,
          height: 32,
          margin: 0,
          [theme.breakpoints.up('md')]: {
            width: 184,
            height: 26,
          },
          [theme.breakpoints.up('lg')]: {
            width: 262,
            height: 37,
          },
          [theme.breakpoints.up('xl')]: {
            width: 326,
            height: 46,
          },
        }),
        contactInfo: css({
          paddingLeft: 17,
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
