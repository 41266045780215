import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem } from '@ecp/components';
import { useCheckHasGen10Vehicle } from '@ecp/features/sales/quotes/auto';
import {
  getOfferDetailsForProduct,
  getOfferProductsSelectedByType,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import type { CoveragesFormProps } from './CoveragesForm.js';
import { CoveragesForm as CoveragesFormBase } from './CoveragesForm.js';
import { useStyles } from './CoveragesForm.styles.gmi.js';

export const CoveragesForm: React.FC<CoveragesFormProps> = (props) => {
  const { classes } = useStyles();
  const hasGen10Vehicle = useCheckHasGen10Vehicle();
  const telematics = flagValues[FeatureFlags.TELEMATICS];

  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);
  const autoOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, autoOfferProduct),
  );
  const isEnrolledTelematics = autoOfferDetails?.isEnrolledTelematics;
  const shouldShowTelematicsBanner = telematics && hasGen10Vehicle && isEnrolledTelematics;

  return (
    <CoveragesFormBase
      {...props}
      banner={
        shouldShowTelematicsBanner && (
          <GridItem xs={12} className={classes.autoCoverageDiscountsMsg}>
            <p>
              In-vehicle technology is used to determine your price, applicable savings and to
              service your policy.
            </p>
          </GridItem>
        )
      }
    />
  );
};
