import type { Metadata } from './metadata.js';

const metadata: Metadata = {
  getNewQuoteLinkText: 'Start a new quote',
  mainDuration: 0,
  mainPanelDelay: 0,
  secondaryDuration: 0,
  fadeDuration: 700,
  showDelay: 40,
};

export default metadata;
