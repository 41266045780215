import { Grid, Typography } from '@mui/material';

import { HoursList } from '@ecp/components';
import { ChatHelp } from '@ecp/features/sales/shared/chat';
import { getCurrentPage } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { partner } from '@ecp/partners';

import { PhoneLink } from '../../PhoneLink';
import { useStyles } from './MainContent.styles';

const ContactUsColumn: React.FC<{ showLiveChat?: boolean }> = (props) => {
  const { showLiveChat } = props;
  const { classes, cx } = useStyles();
  const currentPage = useSelector(getCurrentPage);

  return (
    <Grid item className={classes.contactUsColumn}>
      <Grid item xs={12} className={classes.subHeader}>
        <Typography variant='body3Bold'>Contact Homesite</Typography>
      </Grid>
      <Grid item xs={12} className={cx(classes.centerContent, classes.borderedContainer)}>
        <PhoneLink
          withUnderlinedLinkStyle
          number={partner.shared.salesPhoneNumber}
          trackingName='bottom_phone_number_link'
          trackingLabel='phone_number'
          analyticsEventDetail={currentPage as string}
          className={classes.links}
        />
      </Grid>
      {showLiveChat && (
        <Grid item xs={12} className={cx(classes.centerContent, classes.borderedContainer)}>
          <ChatHelp
            displayText='Live Chat'
            className={classes.links}
            trackingLabel='chat'
            trackingName='bottom_chat_button'
            variant='text'
          />
        </Grid>
      )}
    </Grid>
  );
};

const HoursColumn: React.FC = () => {
  const { classes, cx } = useStyles();

  return (
    <Grid item className={cx(classes.hoursColumn)}>
      <HoursList
        weeklyHours={partner.shared.salesPhoneHours}
        className={classes.hoursOfOperationText}
      />
    </Grid>
  );
};

export type Props = {
  showLiveChat?: boolean;
};

export const ContactInfo: React.FC<Props> = (props) => {
  const { showLiveChat } = props;

  return (
    <Grid container item xs={12} lg={6}>
      <ContactUsColumn showLiveChat={showLiveChat} />
      <HoursColumn />
    </Grid>
  );
};
