import { useStyles } from './TipContent.styles';

export const TipContent: React.FC<unknown> = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.paymentTipContainer}>
      <p className={classes.paymentTips}>Payment tips</p>
      <ul className={classes.paymentTipsList}>
        <li>This transaction will appear on your statement as American Family Insurance.</li>
        <li>
          We&apos;ll use this payment method for today&apos;s purchase. To set up automatic payments
          you must enroll in online servicing and set up AutoPay.
        </li>
      </ul>
    </div>
  );
};
