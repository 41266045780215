import { partner } from '@ecp/partners';

import type { Metadata } from './metadata';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  default: {
    ...baseMetadata.default,
    columnContentHeader: 'Access your policy online',
    columnContentText: `Sign up to access your ${partner.shared.partnerDisplayName} property policy 24/7. Once you create your account, you can make any policy changes or file a claim online.`,
  },
};

export default metadata;
