import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LandingPage' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.pageRoot,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      alignItems: 'center',
    },
    width: '100%',
  },
  mainContainer: {
    justifyContent: 'start',
    position: 'relative',
    top: 0,
    borderRadius: 0,
  },
  mainContainerIsRetrieve: {
    [theme.breakpoints.up('lg')]: {
      width: 750,
      justifyContent: 'center',
    },
  },
  mainContentSlider: {
    [theme.breakpoints.up('lg')]: {
      minHeight: 'auto',
    },
  },
}));
