import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'UnsupportedProducts' })((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  productsHeader: {
    textAlign: 'center',
  },
  productsSubHeader: {
    ...theme.typography.body3,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 20,
  },
  // mobile styles
  mobileList: {
    justifyContent: 'center',
    width: '100%',
  },
  mobileListItem: {
    backgroundColor: theme.palette.grey[200],
    padding: 15,
    borderRadius: 8,
    justifyContent: 'flex-start',
  },
  mobileProductIcon: {
    // defined by override
  },
  mobileProductTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  mobileProductTextHeader: {
    ...theme.typography.body1Bold,
  },
  mobileProductText: {
    ...theme.typography.body2,
  },
  // desktop styles
  desktopList: {
    gap: 30,
    display: 'flex',
    flexDirection: 'row',
  },
  desktopListItem: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 15px',
    gap: 10,
    // flex-grow and flex-basis are needed to make the list items the same width
    flexGrow: 1,
    flexBasis: 0,
  },
  desktopProductIcon: {
    // defined by override
  },
  desktopProductTextHeader: {
    ...theme.typography.body1Bold,
  },
  desktopProductText: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
}));
