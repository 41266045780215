import type { CSSInterpolation } from '@ecp/themes/base';
import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './DiscountsBlock.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        discountsBlockContent: css({
          padding: 20,
        }),
        gmDiscountBanner: css({
          display: 'flex',
          alignItems: 'center',
          backgroundColor: theme.palette.info.light,
          [theme.breakpoints.down('md')]: {
            '& img': {
              marginTop: 5,
            },
          },
          padding: 20,
          '& img': {
            float: 'left',
            marginRight: 15,
          },
          '& > p': {
            marginLeft: 10,
          },
        }),
        gmDiscountIcon: css(theme.mixins.setColorSvg(theme.palette.icon.hover) as CSSInterpolation),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
