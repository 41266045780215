import { FooterRedesign } from '@ecp/features/sales/shared/components';

export const AppFooter: React.FC = () => {
  return (
    <FooterRedesign
      showLiveChat={false}
      copyrightTextElement={
        <>
          © {new Date().getFullYear()} Homesite Insurance Group Incorporated. All Rights Reserved.
        </>
      }
      disclaimerTextElement={
        <>
          Disclaimer: Policies are underwritten and issued by member companies and affiliates of
          Homesite Group Incorporated. In Texas, certain property coverages may be written by
          Ranchers & Farmers Mutual Insurance Company. Coverages, features, discounts, and privacy
          policies vary among these insurers.
        </>
      }
    />
  );
};
