import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './ProductUpsells.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        mobileProductIcon: css({
          width: 46,
          height: 46,
        }),
        desktopProductIcon: css({
          width: 46,
          height: 46,
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
