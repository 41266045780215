import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './RetrieveForm.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        root: css({
          marginTop: 0,
        }),
        subtitle: css({
          ...theme.typography.subtitle,
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
