import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'FooterRedesign' })((...[theme]) => ({
  root: {
    ...theme.mixins.footerRoot,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 0,
    paddingTop: 40,
    [theme.breakpoints.down('xl')]: {
      height: 'auto',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 15,
      boxShadow: 'none',
    },
  },
  maxWidthContainer: {
    width: '100%',
    maxWidth: 1084,
    alignSelf: 'center',
  },
  footerPaddingContainer: {
    width: '100%',
    paddingBottom: 20,
    [theme.breakpoints.down('xl')]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  coverageFooter: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 177,
    },
  },
  checkoutFooter: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 102,
    },
  },
  hasSavings: {
    [theme.breakpoints.down('xl')]: {
      height: 'auto',
    },
  },
}));
