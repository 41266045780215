import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  mvrText:
    'We are unable to retrieve the driving record or credit report. Please contact us to continue your quote.',
};

export default metadata;
